<template>
  <layout  name="HomeLayout">
    <div class="Home">
      <div class="uk-section uk-margin-remove-bottom uk-padding-remove">
        <div class="uk-cover-container uk-margin-remove-top uk-padding-remove-top">
          <canvas width="800" height="650"></canvas>
          <img src="../../assets/404_page_cover.jpg" alt="" uk-cover>
        </div>
      </div>
    </div>
  </layout>
</template>
<script>
import Layout from '../../layouts/Layout'
export default {
  name: "Home",
  components: {
      Layout,
    },
}
</script>